import React, { useEffect } from 'react';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/fullscreen.png';
import 'leaflet-fullscreen/dist/fullscreen@2x.png';
// XXX: Ugh. These come from leaflet distribution but for some reason
// can't import the directly, only from inside src
import markerIcon from '../assets/marker-icon.png';
import markerIconRetina from '../assets/marker-icon-2x.png';
import markerShadow from '../assets/marker-shadow.png';
import staticMap from '../assets/mapbox[-180 TO 180][-85 TO 85].png';
import './Map.css';

Object.assign(Leaflet.Icon.Default.prototype.options, {
    iconUrl: markerIcon,
    iconRetinaUrl: markerIconRetina,
    shadowUrl: markerShadow,
});

// Even settings urls is not enough, need to monkeypatch, because iconUrl
// in icon options is supposed to be just a suffix
// TODO: If it's a problem, do like we did in erk3: just set everything in marker constructor
// Leaflet.Icon.Default.prototype._getIconUrl = function(name) {
//     return Leaflet.Icon.prototype._getIconUrl.call(this, name);
// };

type Props = {
    height?: string;

    markers: {
        lat: number;
        lng: number;
    }[];

    countries: {
        code: string;

        coords: {
            lat: number;
            lng: number;
        };
    }[];
};

export default function Map(props: Props) {
    const container: React.RefObject<HTMLDivElement> = React.createRef();

    useEffect(() => {
        const containerDiv = container.current;

        if (!containerDiv) {
            throw new Error('Container not mounted?');
        }

        let map: Leaflet.Map;

        map = Leaflet.map(containerDiv, {
            // Usually comes from mapbox layers, so if mapbox token is missing, map has
            // not defined maxZoom, which doesn't work with cluster layer
            maxZoom: 18,
            fullscreenControl: true,
        });

        // Starting with this static view, otherwise the view
        // goes outside map bounds, which is coorect, but aesthetically
        // displeasing
        map.setView([36.85, 0], 1);
        map.setMinZoom(1);

        const copyLongitudes = [-180 - 360, -180, 180];

        map.setMaxBounds([
            [-85, -180],
            [85, 180],
        ])

        for (const lon of copyLongitudes) {
            // https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/[-180,-85,180,85]/1280x1280?access_token=
            const staticMapLayer = new Leaflet.ImageOverlay(staticMap, [
                [-85, lon],
                [85, lon + 360],
            ], {
                attribution: `
                    <a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox" role="listitem">© Mapbox</a>
                    <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a>
                `,
            });

            map.addLayer(staticMapLayer);
        }

        map.addControl(new Leaflet.Control.Scale({
            imperial: false,
            position: 'bottomleft',
        }));

        let features = Leaflet.markerClusterGroup();
        let countries = Leaflet.featureGroup();

        function updateMap() {
            map.removeLayer(features);
            map.removeLayer(countries);

            // TODO: Why are empty markers not evaluated as <false>
            if(props.markers.length > 0) {
                features = Leaflet.markerClusterGroup();
                features.addLayers(props.markers.map(coords => Leaflet.marker(coords)));

                map.addLayer(features);
                // this.zoomTo(this.features.getBounds());
            }

            if(props.countries && props.countries.length > 0) {
                const style = {
                    radius: 5,
                    color: 'rgb(255, 0, 0)',
                    weight: 1,
                    fillOpacity: 0.3,
                };

                const markers = props.countries.map(country => {
                    const marker = Leaflet.circleMarker(country.coords, style);

                    if(country.code) {
                        // (Ant)arctica doesn't have one
                        marker.bindTooltip(country.code).openTooltip();
                    }

                    return marker;
                });

                countries = Leaflet.featureGroup(markers);
                map.addLayer(countries);
            }
        }

        updateMap();

        return () => {
            map.remove();
        };
    });

    return (
        <div
            ref={container}
            style={{ height: props.height ?? '400px' }}
        >
        </div>
    );
};

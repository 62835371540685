import axios from 'axios';
import { z } from 'zod';
import { DOI, loadAttachedJSON, loadMetadata, publicAPIList } from '../../common/utils/api';
import { Config } from '../../common/utils/config';

const lineageThSchema = z.object({
    id: z.number(),
    code: z.string(),
    doi: z.string().optional(),
});

const shClusterSchema = z.object({
    type: z.literal('DSHCluster'),
    id: z.string(),

    attributes: z.object({
        name: z.string(),
        is_singleton: z.boolean(),
        designators: z.array(z.unknown()),
        has_conflict: z.boolean(),
        is_active: z.boolean(),
        sequence_count: z.number(),
        sequence_count_core: z.number(),
        threshold: z.string(),
        version: z.string(),

        previous_versions: z.array(z.object({
            id: z.number(),
            code: z.string(),
            sequences_carried_over: z.number(),
            sequences_total: z.number(),
        })),

        taxonomy: z.object({
            // TODO: Need an example
            synonyms: z.array(z.object({
                name: z.string(),
            })),

            lineage: z.array(z.object({
                taxon: z.object({
                    id: z.number(),
                    name: z.string(),
                }),

                th: lineageThSchema.optional(),
            })),
        }),
    }),

    relationships: z.object({
        doi: z.object({
            data: z.object({
                type: z.literal('works'),
                id: z.string(),
            }),
        }),
        taxon_node: z.object({
            data: z.object({
                type: z.literal('Taxon'),
                id: z.string(),
            }),
        }),
    })
});

const accesionNumberSchema = z.object({
    database: z.string(), // TODO: enum
    accno: z.string(),
});

// Licence comes in as a plutof-internal ID.
// TODO: Add this to the eventual-json-format-update request list
const LICENCES: Record<string, string> = {
    1: 'Attribution (CC BY)',
    2: 'Attribution-ShareAlike (CC BY-SA)',
    3: 'Attribution-NoDerivs (CC BY-ND)',
    4: 'Attribution-NonCommercial (CC BY-NC)',
    5: 'Attribution-NonCommercial-ShareAlike (CC BY-NC-SA)',
    6: 'Attribution-NonCommercial-NoDerivs (CC BY-NC-ND)',
};

const shDataSequenceImageSchema = z.object({
    id: z.string(),
    title: z.string(),
    license: z.string().transform(id => LICENCES[id] ?? id) , // TODO: Enum. Also license is ID here, so it's useless
    rights_owner: z.string().optional(),
    author: z.string().optional(),

    download_links: z.object({
        large: z.string(),
        medium: z.string(),
        small: z.string(),
    }),
});

const shDataSequenceSchema = z.object({
    id: z.number(),

    accnos: z.array(accesionNumberSchema),

    sh_colours: z.array(z.object({
        distance: z.string(),
        hex_colour: z.string(),
        sh_code: z.string(),
    })),

    its_sequence: z.string(),
    country_name: z.string(),
    dna_source: z.string(),
    type: z.string().optional(),

    images: z.array(shDataSequenceImageSchema).optional(),

    unite_taxon_name: z.string().optional(),
    insd_taxon_name: z.string().optional(),
    ordering: z.number(),
    ecm_lineage: z.string().optional(),

    // Don't even have a SH on hand for which this is present,
    // just looking at the old code
    interacting_taxa: z.array(z.object({
        taxon_name: z.string(),
    })).optional(),

    latitude: z.string().optional(),
    longitude: z.string().optional(),

    is_reference: z.coerce.boolean(),
    is_representative: z.coerce.boolean(),

    collapse_level: z.number(),
    collapse_parent_id: z.number(),
    // Example with this missing: 10.15156/BIO/SH1309305.09FU (NB! This is GIANT, like 100+Mb of shdata)
    collapse_parent_sequence_id: z.number().optional(),

    is_chimeric: z.coerce.boolean(),
    low_quality: z.coerce.boolean(),
    is_excluded: z.coerce.boolean(),
});

const shDataRefseqSchema = z.object({
    id: z.number(),
    set_by: z.string(),
    set_time: z.string(),
    derived_from_type: z.boolean(),

    accnos: z.array(accesionNumberSchema),
});

const shDataSchema = z.object({
    species_hypotheses: z.object({
        sh_code: z.string(),
        distance: z.string(),
        sequence_count: z.number(),
        distribution_graph: z.string().optional(),

        average_distance: z.string().optional(),
        maximum_distance: z.string().optional(),
        pairwise_distance_count: z.coerce.number().optional(),

        identifications: z.array(z.object({
            count: z.number(),
            rank: z.string(),
            taxon_name: z.string(),
        })),

        sequences: z.array(shDataSequenceSchema),

        reference_sequence: shDataRefseqSchema.optional(),
        representative_sequence: shDataRefseqSchema.optional(),

        lineage: z.object({
            kingdom: z.string().optional(),
            phylum: z.string().optional(),
            'class': z.string().optional(),
            order: z.string().optional(),
            family: z.string().optional(),
            genus: z.string().optional(),
        }),

        taxon: z.object({
            taxon_name: z.string(),
            rank: z.string(),
            full_taxon_name: z.string(),
        }),

        taxon_hypothesis: z.object({
            th_code: z.string(),
            doi: z.string(),
        }).optional(),

        annotated_by: z.array(z.string()),

        // TODO: Need an example of this
        ecm_lineages: z.array(z.object({
            ecm_lineage: z.string(),
            count: z.number(),
        })).optional(),

        // TODO: Need an example of this
        interacting_taxa: z.array(z.object({
            taxon_name: z.string(),
            count: z.number(),
        })).optional(),
    }),

    // Why are there two distributions? Which is correct?
    // Are lat/lng in both? Are country_lat/country_lng in both?
    // Why are some fields only present in some cases?
    distribution: z.array(z.object({
        lat: z.coerce.number().optional(),
        lng: z.coerce.number().optional(),
        country: z.string(),
        country_code: z.string(),
        country_lat: z.coerce.number().optional(),
        country_lng: z.coerce.number().optional(),
        count: z.number(),
        accnos: z.array(z.string()),
    }))
});

export type SHCluster = z.infer<typeof shClusterSchema>;
export type SHData = z.infer<typeof shDataSchema>;
export type SHDataReferenceSequence = z.infer<typeof shDataRefseqSchema>;
export type SHDataSequence = z.infer<typeof shDataSequenceSchema>;
export type SHDataSequenceImage = z.infer<typeof shDataSequenceImageSchema>;
export type SHLineageTh = z.infer<typeof lineageThSchema>;
export type SequenceAccesionNumber = z.infer<typeof accesionNumberSchema>;

const loadSHSchema = publicAPIList(shClusterSchema);

async function loadSH(config: Config, params: { code?: string, doi?: string }): Promise<SHCluster> {
    const response = await axios.get(`${config.external.plutof.api}/public/dshclusters/`, { params });
    const parsedResponse = loadSHSchema.parse(response.data);

    if (parsedResponse.data.length === 1) {
        return parsedResponse.data[0];
    } else if (parsedResponse.data.length === 2) {
        throw new Error(`SH with params ${params} couldn't be uniquely identified`);
    } else {
        throw new Error(`SH with params ${params} not found`);
    }
}

async function loadSHData(doi: DOI): Promise<SHData> {
    const response = await loadAttachedJSON(doi, shDataSchema);

    return response;
}

// XXX Replace SHData image links loaded from json file with links from filerepo API.
async function replaceImageLinks(config: Config, shData: SHData) {
    const imageData = shData.species_hypotheses.sequences.flatMap(sequence => sequence.images ?? []);

    const files = await Promise.all(imageData.map(image =>
        axios.get(`${config.external.plutof.api}/public/files/${image.id}/`))
    );

    imageData.forEach(image => {
        const file = files.find(file => file.data.data.id === image.id);

        // Large is large, medium is large becasue we don't have a medium, small is small
        image.download_links.large = file?.data.data.attributes.download_links.large_link;
        image.download_links.medium = file?.data.data.attributes.download_links.large_link;
        image.download_links.small = file?.data.data.attributes.download_links.small_link;
    });
}

export async function loadSpeciesHypothesis(config: Config, doi: DOI) {
    const cluster = await loadSH(config, { doi: doi.id });
    const shData = await loadSHData(doi);

    // XXX
    await replaceImageLinks(config, shData);

    return {
        cluster,
        shData,
    };
};

export async function loadSpeciesHypothesisWithCode(config: Config, shCode: string) {
    const cluster = await loadSH(config, { code: shCode });
    const metadata = await loadMetadata(config, cluster.relationships.doi.data.id);

    const shData = await loadSHData(metadata);

    return {
        cluster,
        speciesHypothesis: shData,
        metadata,
    };
};
